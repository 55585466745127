import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getItem(key: string) {
    return localStorage.getItem(key);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  setObject(key: string, obj: object) {
    this.setItem(key, JSON.stringify(obj));
  }

  getObject(key: string) {
    const item = this.getItem(key);

    return item ? JSON.parse(item): null;
  }
}
