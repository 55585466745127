
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  public reloadSellComponent: EventEmitter<void> = new EventEmitter<void>();
  public $reloadSelectedProject: EventEmitter<string> = new EventEmitter<string>();
  public $reloadSelectedTimezone: EventEmitter<number> = new EventEmitter<number>();

  getOffset(value: string): number {
    const newMatch = value.match(/UTC[\W]\d{2}:\d{2}/gi);
    if (!newMatch) throw new Error('Invalid utcOffset');
    const result = newMatch[0].slice(3, newMatch[0].length); //extraemos 3 del string match para quitar "UTC"
    const number = Number(result.split(':').join('.')); // Quitamos los dos puntos y unimos el resultado
    //Evaluamos si no es numero es porque se encontró con el 000
    if (isNaN(number)) {
      return 0;
    }
    return number;
  }
}
