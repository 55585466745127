import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { INotification } from '../interfaces/notification.interface';

type Severity = 'success' | 'info' | 'warn' | 'error';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  onNotifyEvent$ = new Subject<any>();
  public $notification: EventEmitter<INotification> = new EventEmitter<INotification>();
  constructor() {}
  
  sendSuccessNotification(message: string){
    this.$notification.emit({
      severity:'success',
      message
    });
  }
  sendErrorNotification(message: string){
    this.$notification.emit({
      severity: 'error',
      message
    });
  }

  toast(severity: Severity = 'success', summary: string = '', life?:number) {
    this.onNotifyEvent$.next({
      key: 'top-center',
      severity: severity,
      summary: summary,
      life: life
    });
  }
}