<p-toast position="top-center" key="countryChanged"></p-toast>

<div style="margin-right: 20px" class="flex flex-row align-items-center">
  <div class="hidden sm:block">
    <form class="min-w-10rem w-12rem max-w-12rem">
      <div class="label-form"><strong>Project</strong></div>
      <p-skeleton
        *ngIf="showLoadSkeleton"
        shape="rectangle"
        height="2.5rem"
      ></p-skeleton>
      <p-dropdown
        *ngIf="!showLoadSkeleton"
        [options]="projectsCatalog"
        [filter]="false"
        [showClear]="false"
        [formControl]="projectControl"
        [disabled]="disabledProject"
        (onChange)="onSelectProject($event)"
        placeholder="Select project"
        optionLabel="casinoName"
      ></p-dropdown>
    </form>
  </div>

  <div class="hidden sm:block ml-3">
    <form class="min-w-10rem w-12rem max-w-12rem">
      <div class="label-form"><strong>Time Zone</strong></div>
      <p-skeleton
        *ngIf="showLoadSkeleton"
        shape="rectangle"
        height="2.5rem"
      ></p-skeleton>
      <p-dropdown
        *ngIf="!showLoadSkeleton"
        [options]="timeZoneCat"
        [filter]="false"
        [showClear]="false"
        [formControl]="timeZoneControl"
        (onChange)="onSelectTimeZone($event)"
        optionLabel="name"
        placeholder="Select time zone"
      ></p-dropdown>
    </form>
  </div>

  <!-- DISEÑO PARA CELULARES -->
  <div class="block sm:hidden">
    <form class="min-w-3rem w-3rem max-w-3rem">
      <div class="label-form"><strong>Project</strong></div>
      <p-skeleton
        *ngIf="showLoadSkeleton"
        shape="rectangle"
        height="2.5rem"
      ></p-skeleton>
      <p-dropdown
        *ngIf="!showLoadSkeleton"
        [options]="[]"
        [filter]="false"
        [showClear]="false"
        placeholder="Select project"
        optionLabel="casinoName"
        [formControl]="projectControl"
        (onChange)="onSelectProject($event)"
      ></p-dropdown>
    </form>
  </div>
  <div class="block sm:hidden">
    <form class="min-w-3rem w-3rem max-w-3rem">
      <div class="label-form"><strong>Time Zone</strong></div>
      <p-skeleton
        *ngIf="showLoadSkeleton"
        shape="rectangle"
        height="2.5rem"
      ></p-skeleton>
      <p-dropdown
        *ngIf="!showLoadSkeleton"
        [options]="[]"
        [filter]="false"
        [showClear]="false"
        placeholder="Select time zone"
      ></p-dropdown>
    </form>
  </div>
  <div class="mt-1">
    <p-menu styleClass="w-13rem" #menu [popup]="true" [model]="items"></p-menu>
    <a
      [style]="{
        width: '70px',
        'padding-left': '0px',
        background: 'transparent',
        border: '1px solid transparent',
        'box-shadow': '0px 0px 0px rgb(15 139 253 / 30%)',
        'margin-left': '10px'
      }"
      pButton
      icon="pi pi-angle-down"
      (click)="menu.toggle($event)"
    >
      <p-avatar pBadge size="large" shape="circle" image="{{ profilePicture }}">
      </p-avatar>
    </a>
  </div>
</div>
