import { Component, OnInit, Output, EventEmitter, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ApiService } from 'src/app/core/http/api.service';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { Utilities } from 'src/app/core/tools/utilities';
import { ApiCore } from '../../../../config/api';
import { HeaderCreditsService } from '../../Services/header-credits.service';
import { Subscription } from 'rxjs';
import { AppBreadcrumbService } from '../../Services/app.breadcrumb.service';
import { Country } from 'src/app/shared/interfaces/country.interface';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SessionService } from 'src/app/core/auth/session.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { Aggregator } from 'src/app/pages/auth/login/interfaces/aggregator.interface';
import { TimeZone } from './interfaces/time-zone.interface';
import { HeaderProjectService } from '../../Services/header-project.service';
import { EventService } from 'src/app/pages/home/Configuration/services/utc.service';

@Component({
  selector: 'app-header-credits',
  templateUrl: './header-credits.component.html',
  styleUrls: ['./header-credits.component.css'],
})
export class HeaderCreditsComponent implements OnInit {
  public selectedProject?: Aggregator | null;
  public projectsCatalog: Aggregator[] = [];
  public projectControl = new FormControl(this.selectedProject);
  public timeZoneCat: TimeZone[] = [];
  public selectedTimeZone?: TimeZone | null;
  public timeZoneControl = new FormControl(this.selectedTimeZone);
  public disabledProject: boolean = false;

  isClose: boolean = true;
  @Output() messageEvent = new EventEmitter<boolean>();
  alertIncompleteData = false;
  isIncomplete: boolean = false;
  disabledCountry: boolean = false;
  userAggregatorId: string = '';

  items: MenuItem[] = [];
  profilePicture: any = 'assets/images/imageProfile.png';
  displaySidebar = false;
  iconSidebar = 'pi pi-cog';
  titleSidebar = 'Configuración';
  renderComponent = false;
  creditReserve = 0;

  creditReserveSub: Subscription | undefined;
  profilePictureSub: Subscription | undefined;

  hideCreditReserve: boolean = false;
  //ISSUE 25 countries dropdown
  showLoadSkeleton: boolean = false;
  listCountries: Country[] = [];
  defaultCountry: string = 'clgh07za1000308la83xp8d1q';
  public fb: FormBuilder = inject(FormBuilder);
  public myFormCountry: FormGroup = this.fb.group({
    country: [this.defaultCountry, Validators.required],
  });

  constructor(
    private i18nService: I18nService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private apiService: ApiService,
    private headerCreditsService: HeaderCreditsService,
    private headerProjectService: HeaderProjectService,
    private breadcrumb: AppBreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private toastService: ToastService,
    private currencyService: CurrencyService,
    private eventService: EventService
  ) {
    //this.getOperatorConfig();
    this.i18nService.localeEvent.subscribe({
      next: () => {
        this.useTranslate();
      },
    });

    this.creditReserveSub = this.headerCreditsService.creditReserve.subscribe(
      (creditReserve) => {
        this.creditReserve = creditReserve;
      }
    );

    this.profilePictureSub = this.headerCreditsService.profilePicture.subscribe(
      (profilePicture) => {
        this.profilePicture = profilePicture;
      }
    );
  }

  LoadUserData() {
    this.apiService.list(ApiCore.USER + '/me').subscribe(
      (res) => {
        if (res.data.profilePictureUrl) {
          this.profilePicture = res.data.profilePictureUrl;
        }

        if (res.data.roleId === 'cm1zgzooc000108l7h5302q2b') {
          this.projectControl.disable();
        }

        const selectedProject =
          this.sessionService.getObject('selectedProject');

        if (selectedProject) {
          this.selectedProject = selectedProject;
        }

        if(res.data.aggregatorId) {
          this.userAggregatorId = res.data.aggregatorId;
        }

        this.loadProjects();
      },
      (error) => {}
    );
  }

  useTranslate() {
    this.translate.use(this.i18nService.getLanguage());
  }

  isClosedConfig = () => {
    this.messageEvent.emit(this.isClose);
  };

  ngOnInit(): void {
    this.LoadUserData();
    this.loadLocalStorageValue();
    this.getTimezoneCat();

    // this.getCountriesList();
    const profileString = this.translate.instant('login.user');
    const logoutString = this.translate.instant('lobby.logout');
    this.items = [
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        command: () => this.logout(),
      },
    ];

    //this.getCreditReserveValue();

    this.headerCreditsService.disableGlobalCountryComponent.subscribe((res) => {
      this.disabledCountry = res;
    });

    this.headerProjectService.disableGlobalProjectComponent.subscribe((res) => {
      this.disabledProject = res;
    });
    // let routeData = this.route.snapshot.children[0].data;
    // this.hideCreditReserve = routeData['hideCreditReserve'] ?? false;
  }

  openProfile() {}

  openConfig = () => {
    this.displaySidebar = true;
    this.renderComponent = true; //TODO: RENDERIZAR EL COMPONENTE DEL CONFIGURATION
    //this.router.navigate(['/app/configuration']);
  };

  closeConfig = () => {
    this.displaySidebar = false;
  };

  onCloseConfig = () => {
    this.renderComponent = false;
    //SE UTILIZA PARA PODER LLAMAR A ACTUALIZAR LOS DATOS DE LA APP
    // this.isClosedConfig()
  };

  logout() {
    this.auth.logout();
  }

  getOperatorConfig = () => {
    this.apiService.GetOperatorConfig().subscribe((response) => {
      const {
        timeZoneCatId,
        languageCatId,
        logoUrl,
        casinoName,
        logoCasinoUrl,
        contactEmail,
        countryCodeId,
        contactPhone,
        mesaggingServices,
        timeZoneCasinoCatId,
        languageCasinoCatId,
      } = response.data;
      let requeredConfigData = {
        operator: {
          timeZoneCatId,
          languageCatId,
          logoUrl,
        },
        casino: {
          casinoName,
          logoCasinoUrl,
          contactEmail,
          countryCodeId,
          contactPhone,
          mesaggingServices,
          timeZoneCasinoCatId,
          languageCasinoCatId,
        },
      };
      //requeredConfigData.casino.casinoName = null;
      let operator = Utilities.hasNull(requeredConfigData.operator);
      let casino = Utilities.hasNull(requeredConfigData.casino);
      if (operator || casino) {
        this.alertIncompleteData = true;
        this.isIncomplete = true;
      }
    });
  };

  goToConfig = (data: boolean) => {
    this.router.navigate(['/app/configuration'], {
      queryParams: { incomplete: data },
    });
  };

  getCreditReserveValue = () => {
    //console.log('Traer valor de Reserva de créditos');

    this.apiService.list(ApiCore.DASHLET + 'creditReserve').subscribe((res) => {
      //console.log('Traer valor de Reserva de créditos');
      //console.log(res);
      this.creditReserve = res.data[0].amount;
    });
  };

  getCountriesList = () => {
    this.showLoadSkeleton = true;
    //TODO: Cambiar la api o agregar filtro para obtener paises que tienen proyectos
    //CUANDO PROYECTOS Se relacione con los paises
    // this.apiService.list(`${ApiCore.PROJECT}get-countries-cat?isActive=true`).subscribe(response => {
    //IS ACTIVE 1 for true ,0 for false
    //Vienen desde operator los countryCat
    try {
      this.apiService
        .list(`operator/all-countries-projects?isActive=1`)
        .subscribe((res) => {
          const response = res.data;
          if (response) {
            this.listCountries = response.data as Country[];
          }

          this.showLoadSkeleton = false;
        });
    } catch (error) {
      console.error(error);
    }
  };

  setGlobalCountryBase(idCountry: string) {
    //TODO: idCountry contiene el pais que se basará toda la aplicación, almacenado en localStorage
    this.sessionService.setItem('idCountry', idCountry);
    this.setGlobalCurrency(idCountry);

    //Se emite el listener para cambiar la configuración global del country
    this.headerCreditsService.reloadGLobalCountryComponent.emit();
    this.toastService.onNotifyEvent$.next({
      key: 'countryChanged',
      severity: 'success',
      summary: 'País cambiado con éxito',
    });
    //METODO PARA GUARDAR EN QUERYParam
    // const queryParams: Params = { globalCountry: idCountry };

    // this.router.navigate(
    //   [],
    //   {
    //     relativeTo: this.activatedRoute,
    //     queryParams,
    //     queryParamsHandling: 'merge', // remove to replace all query params by provided
    //   }
    // );
  }

  private setGlobalCurrency(idCountry: string): void {
    const country = this.listCountries.find(
      (lcountry) => lcountry.id === idCountry
    );

    this.sessionService.setItem(
      'currencycode',
      country?.currencyCode ? country.currencyCode : ''
    );
    this.sessionService.setItem(
      'currencysymbol',
      country?.currencySymbol ? country.currencySymbol : ''
    );
    this.currencyService.currencyCodeSet = country?.currencyCode ?? 'MXN';
    this.currencyService.currencySymbolSet = country?.currencySymbol ?? '$';
    this.currencyService.reloadGLobalCountryProjects.emit();
  }

  private loadLocalStorageValue(): void {
    //verifico si es que existe el idCountry en el localStorage
    const idCountry = this.sessionService.getItem('idCountry');
    if (!idCountry) return;
    //Como existe trato de asignarle ese valor a mi input
    const countryControl = this.myFormCountry.get('country');
    if (countryControl) {
      countryControl.setValue(idCountry);
    }
    return;
  }

  loadProjects() {
    this.showLoadSkeleton = true;

    // get-all-projects
    this.apiService.get(`${ApiCore.AGGREGATOR}get-all-projects`).subscribe({
      next: (res) => {
        this.projectsCatalog = res;
        this.showLoadSkeleton = false;

        if(this.userAggregatorId) {
          this.selectedProject = res.find((item: any) => {
            return item.id === this.userAggregatorId;
          });
          this.sessionService.setObject('selectedProject', this.selectedProject ?? {});
        }

        this.projectControl.setValue(this.selectedProject);
        this.eventService.$reloadSelectedProject.emit(this.selectedProject?.id);
      },
      error: (error) => {
        this.showLoadSkeleton = false;
        console.error(error);
      },
    });
  }

  onSelectProject(e: any) {
    this.selectedProject = e.value;
    this.sessionService.setObject('selectedProject', e.value);
    this.eventService.$reloadSelectedProject.emit(e.value.id);
  }

  getTimezoneCat() {
    this.showLoadSkeleton = true;

    this.apiService
      .get(`${ApiCore.CATALOGS}time-zone-cat?isActive=true`)
      .subscribe({
        next: (res) => {
          this.timeZoneCat = res.data;
          this.showLoadSkeleton = false;

          if(!this.sessionService.getObject('selectedTimeZone')) {
            this.sessionService.setObject('selectedTimeZone', this.timeZoneCat[0]);
          }

          this.selectedTimeZone = this.sessionService.getObject('selectedTimeZone');
          this.timeZoneControl.setValue(this.selectedTimeZone);
          const number = this.eventService.getOffset(
            this.selectedTimeZone?.name!
          );
          this.eventService.$reloadSelectedTimezone.emit(number);
          console.log({number});
        },
        error: (error) => {
          this.showLoadSkeleton = false;
          console.error(error);
        },
      });
  }

  onSelectTimeZone(e: any) {
    this.selectedTimeZone = e.value;
    this.sessionService.setObject('selectedTimeZone', e.value);
    console.log({timeZoneCat: e.value});
    const number = this.eventService.getOffset(e.value.name);
    this.eventService.$reloadSelectedTimezone.emit(number);
    console.log({number});
  }
}
