import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderProjectService {
  public disableGlobalProjectComponent: EventEmitter<boolean> =
    new EventEmitter<boolean>(false);

  disableProjects() {
    this.disableGlobalProjectComponent.emit(true);
  }

  enableProjects() {
    this.disableGlobalProjectComponent.emit(false);
  }
}
